"use client";

import { lazy, useEffect, useState } from 'react';
import TypewriterEffect from './typewriter';
import { motion } from 'framer-motion';
import { textVariant, zoomIn } from '../lib/motion';

const Spline = lazy(() => import('@splinetool/react-spline'));

export default function SplineModel({screenType}:{screenType:string}) {
  const [loaded, setLoaded] = useState(false);
  const [showComponent, setShowComponent] = useState(false);
  const handleLoad = () => {setTimeout(()=>setLoaded(true),0)};


  useEffect(() => {
    // Preload scenes
    const preloadSplineScene = (url: string) => {
      const iframe = document.createElement("iframe");
      iframe.src = url;
      iframe.style.display = "none";
      document.body.appendChild(iframe);
      iframe.onload = () => {
        document.body.removeChild(iframe);
      };
    };

    if (screenType === "small") {
      preloadSplineScene("https://prod.spline.design/zmHcuRS8IsS0rFMO/scene.splinecode");
    } else {
      preloadSplineScene("https://prod.spline.design/B37C6s4KxRqQ6YVc/scene.splinecode");
    }
  }, [screenType]);

  return (
    <>
     {/* Loader */}
     {(!loaded) &&<div className="loading-spinner tracking-tighter text-md sm:text-lg ml-4 sm:ml-[12em] h-full w-full mt-28 sm:mt-0"> 
      <motion.div 
          animate={{
            opacity: [1, 0, 1], // Blinking effect
          }}
          transition={{
            opacity: {
              duration: 1, // Blink duration
              repeat: Infinity, // Keeps blinking
              repeatType: 'loop', // Loops indefinitely
              ease: 'easeInOut',
            },
           
          }}
          style={{ fontSize: '1.2rem', textAlign: 'center' }} >
            "Hold on ! Good things take time..."
      </motion.div>
      </div>}

      {/* Spline Scene */}
      <motion.div
        initial="hidden"
        whileInView="show" 
        viewport={{ once: true }}
        variants={zoomIn(1,0.9)}
        className={loaded ? `w-full h-full` : `hidden`}
      >
        <Spline
          scene={
            screenType === "small"
              ? "https://prod.spline.design/zmHcuRS8IsS0rFMO/scene.splinecode"
              : "https://prod.spline.design/B37C6s4KxRqQ6YVc/scene.splinecode"
          }
          onLoad={handleLoad}
        />
      </motion.div>
    </>
       
  );
}
